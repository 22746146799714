.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #FFFFFF;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
section {
  margin-top: 10px;
  padding-bottom: 50px;
  text-align: justify;
}

.section-logo{
  margin-top: 80px;
}

footer{
  margin-bottom: 10px;
  padding-top: 25px;
}

.App-link {
  color: #61dafb;
}
ul#app-store li {
  display:inline;
  font-size: 50px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
